import { IMixlOffer, web3EventTypes } from './types';

const INITIAL_NETWORK_STATE = {
  premiumOfferType: IMixlOffer.slack,
  disableExperimentalFeature: true,
};

export const web3Reducer = (state = INITIAL_NETWORK_STATE, action) => {
  switch (action.type) {
    case web3EventTypes.DISABLE_EXPERIMENTAL_FEATURE: {
      return {
        ...state,
        disableExperimentalFeature: action.payload
      };
    }
    case web3EventTypes.UPDATE_PREMIUM_OFFER: {
      return { ...state, premiumOfferType: action.offerType };
    }
    default:
      return state;
  }
};
