import { IonButton } from '@ionic/react';
import { Component } from 'react';

import refreshIcon from '../../assets/Loaders/refresh.svg';
import { Sentry } from '../../Services/SentryLogger';
import { ErrorBoundaryContainer } from './style';

class ErrorBoundaryCOmponent extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null, eventId: null };
  }

  componentDidCatch(error) {
    this.setState({ error });
    Sentry.captureException(error);
  }

  render() {
    if (this.state.error) {
      //render fallback UI
      return (
        <ErrorBoundaryContainer>
          <div className="error-content">
            <p>Please click below to refresh the page</p>
            <IonButton className="refresh-button" onClick={() => window.location.reload()}>
              <div className="button-content">
                <img src={refreshIcon} alt="refresh" />
                <span>Refresh</span>
              </div>
            </IonButton>
            <p className="or-text">OR</p>
            <IonButton
              className="refresh-button"
              onClick={(e) => {
                e.preventDefault();
                window.localStorage.clear();
                window.sessionStorage.clear();
                window.location.href = '/connect';
              }}>
              <div className="button-content">
                <span>Logout</span>
              </div>
            </IonButton>
          </div>
        </ErrorBoundaryContainer>
      );
    } else {
      //when there's not an error, render children untouched
      return this.props.children;
    }
  }
}

export default ErrorBoundaryCOmponent;
