import { stageActionTypes } from './type';

const initialState = {
  selectedStage: 'glo',
  loungeInfo: null,
  loading: false,
  deviceInfo: null,
  eventTimer: 0
};

export const stageReducer = (state = initialState, action) => {
  switch (action.type) {
    case stageActionTypes.SET_SELECTED_STAGE: {
      return { ...state, selectedStage: action.payload };
    }
    case stageActionTypes.SET_LOUNGE_INFO: {
      return { ...state, loungeInfo: action.payload };
    }
    case stageActionTypes.SET_LOADING_STAGE: {
      return { ...state, loading: action.payload };
    }
    case stageActionTypes.UPCOMING_EVENT_TIMER: {
      return { ...state, eventTimer: action.payload };
    }
    case stageActionTypes.SET_DEVICE_INFO: {
      return { ...state, deviceInfo: action.payload };
    }
    default:
      return state;
  }
};
