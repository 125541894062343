import { playerActionTypes } from './types';

const INITIAL_FEED_PLAYER_STATE = {
  currentMusicUrl: '',
  isMusicPlaying: false,
  songDuration: 0,
  playerProgress: 0,
  notificationData: null,
  effectMusicSwiper: 'fade'
};

export const feedPlayerReducer = (state = INITIAL_FEED_PLAYER_STATE, action) => {
  switch (action.type) {
    case playerActionTypes.PAUSE_MUSIC_TRACK: {
      return { ...state, isMusicPlaying: false };
    }
    case playerActionTypes.RESUME_MUSIC_TRACK: {
      return { ...state, isMusicPlaying: true };
    }
    case playerActionTypes.UPDATE_MUSIC_DURATION: {
      return { ...state, songDuration: action.payload };
    }
    case playerActionTypes.UPDATE_PLAYER_PROGRESS: {
      return { ...state, playerProgress: action.payload.playerProgress };
    }
    case playerActionTypes.STOP_MUSIC_TRACK: {
      return { ...state, currentMusicUrl: '', isMusicPlaying: false };
    }

    case playerActionTypes.PLAY_MUSIC_TRACK: {
      return { ...state, currentMusicUrl: action.musicUrl, isMusicPlaying: true };
    }

    case playerActionTypes.SET_NOTIFICATION_DATA: {
      return {
        ...state,
        notificationData: action.payload
      };
    }

    case playerActionTypes.SET_EFFECT_SWIPER: {
      return {
        ...state,
        effectMusicSwiper: action.payload
      };
    }

    default:
      return state;
  }
};
