import { IMixlSong } from 'mixl-audio';

import { IMusicContent } from '../redux/mixl/types';
import { staticBlocks } from './mixlFlow';

export const getMixlSong = (payload: IMusicContent) => {
  console.log('getMixlSong: ', payload);
  if (payload.contentType === 'feed' && payload.content) {
    const binaural_music_url = payload.content?.music_url;
    const permalink_url = payload?.content?.music_url;
    let audio_url = permalink_url;
    if (binaural_music_url) {
      audio_url = binaural_music_url;
    }
    const artwork_url = payload.content.image_url ?? '';
    const result: IMixlSong = {
      id: payload.content.id,
      artwork_url: artwork_url,
      audio_url
    };
    return result;
  }

  return null;
};

export const flowTimer = 'flowTimer';

export const bineauralBeats = 'bineauralBeats';

export const FlowSegments = [
  {
    segment: flowTimer,
    value: 'flow timer'
  },
  {
    segment: bineauralBeats,
    value: 'binaural beats'
  }
];

export const prioritiesList = [
  {
    color: '#7A7A7A',
    id: '1',
    orderindex: '1',
    priority: 'low'
  },
  {
    color: '#FFF',
    id: '2',
    orderindex: '2',
    priority: 'normal'
  },
  {
    color: '#FE4D8A',
    id: '3',
    orderindex: '3',
    priority: 'high'
  },
  {
    color: '#B52758',
    id: '4',
    orderindex: '4',
    priority: 'urgent'
  }
];

const old_generateHoursList = (start, end, myDayTemplate) => {
  const workTemplates = myDayTemplate.filter((e) => !staticBlocks.includes(e.title?.toLowerCase()));
  console.log(workTemplates);

  const hoursArray = [];

  workTemplates.forEach((event) => {
    const startTimeEvent = getDateFrom24Format(event.start);
    const endTimeEvent = getDateFrom24Format(event.end);
    while (startTimeEvent < endTimeEvent) {
      if (start.includes('Z')) {
        console.log(startTimeEvent, endTimeEvent);
        hoursArray.push(startTimeEvent.getUTCHours().toString().padStart(2, '0'));
      } else {
        hoursArray.push(new Date(startTimeEvent).getHours().toString().padStart(2, '0'));
      }
      startTimeEvent.setHours(startTimeEvent.getHours() + 1);
    }
  });

  return hoursArray;
};

export const generateHoursList = (myDayTemplate) => {
  const workTemplates = myDayTemplate.filter((e) => staticBlocks.includes(e.title?.toLowerCase()));

  let startTime;
  let endTime;
  const arrHours = [
    '00:00',
    '01:00',
    '02:00',
    '03:00',
    '04:00',
    '05:00',
    '06:00',
    '07:00',
    '08:00',
    '09:00',
    '10:00',
    '11:00',
    '12:00',
    '13:00',
    '14:00',
    '15:00',
    '16:00',
    '17:00',
    '18:00',
    '19:00',
    '20:00',
    '21:00',
    '22:00',
    '23:00'
  ];
  startTime = getDateFrom24Format('00:00');
  endTime = getDateFrom24Format('23:59');
  // If the end time is earlier in the day than the start time, adjust the end time to be on the next day
  if (endTime <= startTime) {
    endTime.setUTCDate(endTime.getUTCDate() + 1);
  }

  const hoursArray = [];
  let currentTime;

  for (let i = 0; i < arrHours.length; i++) {
    currentTime = getDateFrom24Format(arrHours[i]);
    let isIncluded = false;
    workTemplates.forEach((event) => {
      const startTimeEvent = getDateFrom24Format(event.start);
      const endTimeEvent = getDateFrom24Format(event.end);
      console.log(
        event,
        currentTime,
        startTimeEvent,
        endTimeEvent,
        currentTime >= startTimeEvent,
        currentTime < endTimeEvent
      );
      if (currentTime >= startTimeEvent && currentTime < endTimeEvent) {
        isIncluded = true;
      }
    });
    if (!isIncluded) {
      hoursArray.push(arrHours[i].split(':')[0]);
    }
    // currentTime.setHours(currentTime.getHours() + 1);
  }
  console.log(hoursArray);
  return hoursArray;
};

//time format  : "09:00"
function getDateFrom24Format(time) {
  const currentDate = new Date();
  return new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate(),
    time?.split(':')?.[0],
    0,
    0,
    0
  );
}

function getHourFromDateString(dateString) {
  const date = new Date(dateString);
  return date.getHours().toString().padStart(2, '0'); // Adjusting to the original timezone offset
}
