// Helper functions
import { cloneDeep } from 'lodash';
import { DateTime } from 'luxon';

// Generate a list of hours within the working hours
const generateAvailableHours = (workingStartTime, workingEndTime) => {
  const hours = [];
  const startHour = new Date(workingStartTime).getHours();
  const endHour = new Date(workingEndTime).getHours();
  for (let i = startHour; i < endHour; i++) {
    hours.push(i.toString().padStart(2, '0'));
  }
  return hours;
};

// Convert time in "HH:mm" format to a Date object
const convertTimeToDate = (time, referenceDate) => {
  const [hours, minutes] = time.split(':').map(Number);
  const date = new Date(referenceDate);
  date.setHours(hours, minutes, 0, 0);
  return date;
};

// Check if a time overlaps with any events in a list
const isTimeAvailable = (time, events) => {
  return !events.some((event) => {
    console.log(event);
    const eventStart = new Date(event.startTimestamp).getTime();
    const backupEndTime = DateTime.fromMillis(eventStart).plus({ hours: 1 }).toMillis();
    console.log('isTimeAvailable: backupEndTime ', backupEndTime);
    const endEventTimestamp = event?.endTimestamp ?? backupEndTime;
    console.log('isTimeAvailable: endEventTimestamp ', endEventTimestamp);
    const eventEnd = new Date(endEventTimestamp).getTime();
    console.log('isTimeAvailable: eventEnd ', eventEnd);
    const taskStart = time.getTime();
    const taskEnd = new Date(time).setHours(time.getHours() + 1);
    console.log('isTimeAvailable: ', taskStart, taskEnd, eventStart, eventEnd);
    console.log('isTimeAvailable: ', taskStart < eventEnd && taskEnd > eventStart);
    return taskStart < eventEnd && taskEnd > eventStart;
  });
};

// Check if a time overlaps with any events in the myDayTemplate
const isTimeAvailableInTemplate = (time, myDayTemplate, referenceDate) => {
  return !myDayTemplate.some((event) => {
    const eventStart = convertTimeToDate(event.start, referenceDate).getTime();
    const eventEnd = convertTimeToDate(event.end, referenceDate).getTime();
    const taskStart = time.getTime();
    const taskEnd = new Date(time).setHours(time.getHours() + 1);
    return taskStart < eventEnd && taskEnd > eventStart;
  });
};

function deleteAfterEveryFour(arr: Array<string>) {
  // Initialize a counter to keep track of the position in the array
  let counter = 0;

  // Iterate over the array
  for (let i = 0; i < arr.length; i++) {
    // Increment the counter for each element
    counter++;

    // If the counter reaches 4, delete the next element
    if (counter === 4) {
      // Remove the element at index i + 1
      arr.splice(i + 1, 1);

      // Reset the counter
      counter = 0;
    }
  }

  // Return the modified array
  return [...arr];
}

// Main function to schedule tasks
export const scheduleMixlTasks = (
  tasks: Array<IMixlTask>,
  scheduledTasks: Array<IMixlTask>,
  myDayTemplate: Array<unknown>,
  workingHours: { workingStartTime: string; workingEndTime: string }
) => {
  const newTasks = [...tasks];
  const newScheduledTasks = [];
  const currentDate = new Date();

  const workingStartTime = new Date(workingHours.workingStartTime);
  const workingEndTime = new Date(workingHours.workingEndTime);

  // Generate available hours list
  let hoursList = generateAvailableHours(workingStartTime, workingEndTime);
  const currentHour = new Date().getHours().toString().padStart(2, '0');

  console.log('current hour ', currentHour);
  hoursList = hoursList.filter((hour) => hour > currentHour);

  if (hoursList.length === 0) {
    hoursList = generateAvailableHours(workingStartTime, workingEndTime);
  }

  const finalHoursList = deleteAfterEveryFour(hoursList);

  let taskCounter = 0;
  console.log('available hour list ', finalHoursList);

  // Iterate over the hours and assign tasks
  for (let i = 0; i < finalHoursList.length; i++) {
    if (taskCounter >= newTasks.length) break;

    // Create a new date object for each hour in the list
    const dateWithHour = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate(),
      finalHoursList[i],
      0,
      0,
      0
    );

    // Check if the time is available
    if (
      isTimeAvailable(dateWithHour, scheduledTasks) &&
      isTimeAvailableInTemplate(dateWithHour, myDayTemplate, currentDate)
    ) {
      const enddateWithHour = new Date(dateWithHour);
      enddateWithHour.setHours(dateWithHour.getHours() + 1);

      // Ensure the end time is within the working hours
      if (enddateWithHour > workingEndTime) break;

      console.log(i, taskCounter, dateWithHour, enddateWithHour);
      const taskData = cloneDeep(newTasks[taskCounter]);
      taskData.startTimestamp = dateWithHour.getTime();
      taskData.endTimestamp = enddateWithHour.getTime();
      taskData.isTodayTask = true;
      newScheduledTasks.push(taskData);
      taskCounter++;
    }
  }
  return newScheduledTasks;
};
