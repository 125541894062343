import { SHOW_STATS_MODAL } from './action';

const initialState = {
  showStatsModal: false
};

export const statsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_STATS_MODAL: {
      return { ...state, showStatsModal: action.payload };
    }
    default:
      return state;
  }
};
