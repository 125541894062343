import { isCapacitor, platforInfo } from "../utils/platformUtils";

export const ALLOWED_DURATION_FOR_REVIEW = 60 * 15;

export function getContainerWidth() {
  if (window.innerWidth > 768) {
    return '414px';
  }

  if (isCapacitor && platforInfo.includes('desktop')) {
    return '414px';
  }

  return '100%';
}
