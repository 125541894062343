import { doc, getDoc, setDoc } from 'firebase/firestore';
import { DateTime } from 'luxon';

import { firebaseAuth, firebaseDB } from '../../components/firebase/firebase';
import { setShowStatsModal } from '../../redux/stats/action';

const mixlReviewCollection = 'user_reviews';
const usersCollection = 'glo-users';
const moderatorsCollection = 'gloqal-moderators';

const reviewTypes = {
  SHOW_REVIEW_MODAL: 'SHOW_REVIEW_MODAL',
  SHOW_FEEDBACK_MODAL: 'SHOW_FEEDBACK_MODAL'
};

export type IMixlReview = {
  showReviewModal: boolean;
  showFeedbackModal: boolean;
};

const initialState: IMixlReview = {
  showReviewModal: false,
  showFeedbackModal: false
};

export const shouldShowReviewModal = (isOpen) => {
  return {
    type: reviewTypes.SHOW_REVIEW_MODAL,
    isOpen
  };
};

export const shouldShowFeedbackModal = (isOpen) => {
  return {
    type: reviewTypes.SHOW_FEEDBACK_MODAL,
    isOpen
  };
};

// prettier-ignore
export const handleSubmitUserReview = ({ reviewText, rateValue }) => {
  // dispatch(shouldShowReviewModal(false));
  const uid = firebaseAuth.currentUser?.uid;
  if (!uid) {
    return;
  }
  const docRef = doc(firebaseDB, mixlReviewCollection, uid);
  const expireDate = DateTime.now().plus({ days: 6 }).toMillis();
  setDoc(docRef, { reviewText, rateValue, expireDate }).then(() => {
    console.log('added review successfully');
  });
};

const saveExpirationData = (ref) => {
  const expireDate = DateTime.now().plus({ days: 7 }).toMillis();
  setDoc(ref, { expireDate }).then(() => {
    console.log('added expire date for stats modal successfully');
  });
};

const saveExpirationStatsData = (ref) => {
  const expireDate = DateTime.now().plus({ days: 7 }).toMillis();
  setDoc(ref, { expireDateStats: expireDate }).then(() => {
    console.log('added expire date for stats modal successfully');
  });
};

export const maybeShowReviewModal = () => async (dispatch) => {
  const uid = firebaseAuth.currentUser?.uid;

  if (!uid) {
    return;
  }
  const docRef = doc(firebaseDB, mixlReviewCollection, uid);
  const userRef = doc(firebaseDB, usersCollection, uid);
  const moderatorRef = doc(firebaseDB, moderatorsCollection, uid);
  const moderatorSnapshot = await getDoc(moderatorRef);
  const moderatorData = moderatorSnapshot.data();
  //check stats modal
  getDoc(userRef).then((user_snapshot) => {
    if (user_snapshot.exists()) {
      const userData = user_snapshot.data();
      const expireDateStats = userData?.expireDateStats ?? 0;
      if (Date.now() > expireDateStats) {
        dispatch(setShowStatsModal(true));
        saveExpirationStatsData(userRef);
        return;
      }
    }
  });

  // check review modal
  getDoc(docRef).then((snapshot) => {
    if (!snapshot.exists()) {
      const creatAccountDate = moderatorData?.createdAt;
      const oneDayAfterSavedDate = new Date(creatAccountDate);
      oneDayAfterSavedDate.setDate(oneDayAfterSavedDate.getDate() + 1);
      const today = new Date();
      const isAfterOneDayAfterSavedDate = today > oneDayAfterSavedDate;
      if (isAfterOneDayAfterSavedDate) {
        dispatch(shouldShowReviewModal(true));
        saveExpirationData(docRef);
        saveExpirationStatsData(userRef);
      }
    } else {
      const reviewData = snapshot.data();
      const rateValue: number = reviewData?.rateValue ?? 0;
      const expireDate = reviewData?.expireDate ?? 0;
      //check to show stats modal after 5 days after we show review modal first time
      const fiveDaysAfterFirstReview = new Date(expireDate);
      fiveDaysAfterFirstReview.setDate(fiveDaysAfterFirstReview.getDate() + 5);
      const today = new Date();
      const isAfterFiveDayAfterFirstReview = today > fiveDaysAfterFirstReview;
      if (isAfterFiveDayAfterFirstReview) {
        dispatch(setShowStatsModal(true));
        saveExpirationStatsData(userRef);
      }
      if (rateValue < 5) {
        if (Date.now() > expireDate) {
          dispatch(shouldShowReviewModal(true));
          saveExpirationData(docRef);
          return;
        }
      }
    }
  });
};

export const mixlReviewReducer = (state: IMixlReview = initialState, action) => {
  switch (action.type) {
    case reviewTypes.SHOW_REVIEW_MODAL: {
      return { ...state, showReviewModal: action.isOpen };
    }
    case reviewTypes.SHOW_FEEDBACK_MODAL: {
      return { ...state, showFeedbackModal: action.isOpen };
    }
    default:
      return state;
  }
};
