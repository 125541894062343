export const UPDATE_SAFE_AREA = 'UPDATE_SAFE_AREA';
export const UPDATE_KEYBOARD_HEIGHT = 'UPDATE_KEYBOARD_HEIGHT';

export interface ISafeAreaInsets {
  top: number;
  right: number;
  bottom: number;
  left: number;
}

export interface IStyleData {
  safeAreaInsets: ISafeAreaInsets;
  keyboardHeight: number;
}
