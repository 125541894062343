import { sleep } from '../../utils/miscUtils';
import { isCapacitor } from '../../utils/platformUtils';
import { pauseMusicTrackAction, resumeMusicTrackAction } from '../player/action';
import { drawers } from './type';

export const showOnGoingEventModal = (isOpen, eventData) => {
  if (!isOpen) {
    return {
      type: drawers.SHOW_UPCOMING_EVENT_MODAL,
      payload: null
    };
  }
  return {
    type: drawers.SHOW_UPCOMING_EVENT_MODAL,
    payload: { isOpen, eventData }
  };
};

export const shouldShowMixlPlusModal = (isOpen) => {
  return {
    type: drawers.SHOW_MIXL_PLUS_MODAL,
    isOpen
  };
};
export const shouldShowMobilePromo = (isOpen) => {
  return {
    type: drawers.SHOW_MOBILE_PROMO,
    isOpen
  };
};

export const shouldShowChatLoading = (isLoading) => {
  return {
    type: drawers.UPDATE_LOADING_CHAT,
    isLoading
  };
};

export const openResumeSessionModal = (isOpen) => {
  return {
    type: drawers.SHOW_RESUME_SESSION_MODAL,
    isOpen
  };
};

export const updatePlatformError = (payload) => {
  return {
    type: drawers.SET_PLATFORM_ERROR,
    payload
  };
};

export const mediaDeviceChanged = () => async (dispatch, getState) => {
  const store = getState();

  const newOutputDevice = await navigator.mediaDevices.enumerateDevices().then((devices) => {
    const audioDevices = devices.filter((device) => device.kind === 'audiooutput');
    const defaultDevice = audioDevices.find((device) => device.deviceId === 'default');
    return defaultDevice;
  });
  const currentOutputDevice = store.drawerData.outputDevice;
  if (currentOutputDevice && currentOutputDevice?.groupId === newOutputDevice?.groupId) {
    return;
  }
  console.log('mediaDeviceChanged: new device detected', newOutputDevice, currentOutputDevice);
  dispatch({
    type: drawers.OUTPUT_DEVICE_CHANGED,
    outputDevice: newOutputDevice
  });

  if (isCapacitor) {
    // we dont have this issue in mobile
    return;
  }

  const stopTimer = localStorage.getItem('stopTimer');
  const isMusicPlaying = store.feedPlayerData.isMusicPlaying;
  if (isMusicPlaying) {
    dispatch(pauseMusicTrackAction());
    if (stopTimer !== 'true') {
      await sleep(250);
      dispatch(resumeMusicTrackAction());
    }
  }
};
