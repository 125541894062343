import '@ionic/react/css/core.css';
import '@ionic/react/css/ionic-swiper.css';
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';
import { lazy, Suspense } from 'react';
import 'react-circular-progressbar/dist/styles.css';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { PersistGate } from 'redux-persist/integration/react';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/virtual';

// import MobileApp from './app';
import './assets/css/driver.min.css';
import './assets/scss/light-bootstrap-dashboard-react.scss?v=2.0.0';
import LogoLoader from './components/AnimatedLoader/LogoLoader';
import ErrorBoundaryComponent from './components/ErrorBoundaryComponent';
import { Sentry } from './Services/SentryLogger';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { persistor, store } from './store';
import { loadFonts } from './utils/fontLoader';

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DNS,
  integrations: [new Sentry.BrowserTracing()],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0
});

const MobileApp = lazy(() => import('./app'));

const Loader = (
  <div>
    <LogoLoader />
  </div>
);

const App = () => {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={Loader}>
        <ErrorBoundaryComponent>
          <Suspense fallback={Loader}>
            <MobileApp />
          </Suspense>
        </ErrorBoundaryComponent>
      </PersistGate>
      <ToastContainer position="bottom-center" />
    </Provider>
  );
};

const renderMobileApp = async () => {
  await loadFonts();
  const root = createRoot(document.getElementById('root'));
  root.render(<App />);
};

renderMobileApp();
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();
