import { convertTo24Hour } from './dateUtils';
import { IMixlEvent, IMixlTask } from './Types';

export function getWorkTimeToday(hourList: never[]) {
  console.log('getWorkTimeToday ', hourList);
  const workDuration = hourList.length;
  if (!workDuration) {
    return;
  }
  const startHour = convertTo24Hour(hourList[0]);
  console.log(startHour);
  const endHour = convertTo24Hour(hourList[workDuration - 1]);
  console.log(endHour);
  let startTime = new Date().setHours(startHour, 0, 0, 0);
  startTime = new Date(startTime);
  startTime = `${startTime.getFullYear()}-${startTime.getMonth()}-${startTime.getDate()}T${startTime.getHours().toString().padStart(2, '0')}:${startTime.getMinutes().toString().padStart(2, '0')}`;
  let endTime = new Date().setHours(endHour, 0, 0, 0);
  endTime = new Date(endTime);
  endTime = `${endTime.getFullYear()}-${endTime.getMonth()}-${endTime.getDate()}T${endTime.getHours().toString().padStart(2, '0')}:${endTime.getMinutes().toString().padStart(2, '0')}`;

  console.log(startTime, endTime);

  return {
    startTime: startTime,
    endTime: endTime
  };
}

export function fetchNextTask(tasks) {
  const now = new Date();
  const currentHourStart = new Date(now.setMinutes(0, 0, 0));

  // Filter tasks
  const upcomingTasks = tasks.filter(
    (task) => new Date(task.startTimestamp) >= currentHourStart && task?.status?.status !== 'done'
  );

  // Sort tasks by timestamp
  upcomingTasks.sort((a, b) => new Date(a.startTimestamp) - new Date(b.startTimestamp));

  // Set the first task
  if (upcomingTasks?.length > 0) {
    return upcomingTasks[0];
  }
  return;
}

export const sortTasks = (sortBy, a, b) => {
  if (a[sortBy] && b[sortBy]) {
    return new Date(a[sortBy]) - new Date(b[sortBy]);
  }
  if (a[sortBy] && !b[sortBy]) {
    return 1;
  }
  if (!a[sortBy] && b[sortBy]) {
    return -1;
  }
  return 0;
};

export function convertTasksToEvents(tasks: Array<IMixlTask>) {
  if (!tasks.length) {
    return [];
  }
  return tasks.map((task) => {
    const startDate = new Date(task.startTimestamp);
    const endDate = new Date(task?.endTimestamp || task.startTimestamp); // Adjust end time as needed, e.g., add duration

    // Assuming a default duration of 1 hour for the end time
    endDate.setHours(task?.endTimestamp ? endDate.getHours() : endDate.getHours() + 1);

    const mixlEvent: IMixlEvent = {
      color: '#FF1D7F',
      end: endDate,
      id: task.id,
      start: startDate,
      title: `${task.name}`,
      type: 'work',
      task: task
    };

    return mixlEvent;
  });
}

export function getCurrentDateWithFixedTime(time) {
  const currentDate = new Date();
  const hourMin = time.split('T')?.[1];
  const [hours, minutes] = hourMin.split(':');

  const formattedDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate(),
    parseInt(hours, 10),
    parseInt(minutes, 10)
  );

  const year = formattedDate.getFullYear();
  const month = String(formattedDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(formattedDate.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}T${hours}:${minutes}`;
}

export function getSortedTasks(clickupTasks: Array<IMixlTask>) {
  const totalTasks = [...clickupTasks];
  const moreTasks = [
    ...totalTasks
      .filter((e) => !e?.startTimestamp || e?.startTimestamp === '')
      .filter((e) => e.type != 'break')
      .filter((e) => e?.status?.status !== 'done')
      .sort((a, b) => sortTasks('createdAt', a, b))
  ];
  const topTasks = [
    ...totalTasks
      .filter((e) => e?.isTodayTask)
      .filter((e) => e?.status?.status !== 'done')
      .filter((e) => e?.startTimestamp)
      .sort((a, b) => sortTasks('startTimestamp', a, b))
  ];

  //calculate done tasks

  const now = new Date();
  const startOfWeek = getStartOfWeek(now);
  const endOfWeek = getEndOfWeek(now);

  const doneTasks = [
    ...totalTasks
      .filter((e) => e.type != 'break')
      .filter((e) => e?.status?.status === 'done')
      .filter((item) => {
        const itemDate = new Date(item.createdAt);
        return itemDate >= startOfWeek && itemDate <= endOfWeek;
      })
      .sort((a, b) => sortTasks('createdAt', a, b))
  ];
  return { topTasks, moreTasks, doneTasks };
}

export function sortByPriority(tasks: Array<IMixlTask>) {
  const priorityOrder = { urgent: 1, high: 2, normal: 3, low: 4 };
  const totalTasks = [...tasks];
  const sortedClickupTasks = [...totalTasks].sort((a, b) => {
    const priorityA = a?.priority?.priority || 'normal'; // If priority is null or undefined, default to 'normal'
    const priorityB = b?.priority?.priority || 'normal'; // If priority is null or undefined, default to 'normal'

    return priorityOrder[priorityA] - priorityOrder[priorityB];
  });

  return sortedClickupTasks;
}

export const scrollToCurrentHour = (hoursList) => {
  const now = new Date();
  now.setHours(now.getHours() + 1); // Increment the current hour by 1
  const options = { hour: 'numeric', hour12: true };
  let hour = now.toLocaleString('en-US', options).toLowerCase();
  hour = hour.replace(' ', '').replace('m', '');
  const index = hoursList.findIndex((e) => e === hour);
  const item = document.getElementById(`task-time-${index >= hoursList.length ? hoursList.length : index + 2}`);

  if (item) {
    item.scrollIntoView({ behavior: 'smooth', block: 'end' });
  }
  return index < 0 ? 1 : index;
};

function getStartOfWeek(date) {
  const start = new Date(date);
  const day = start.getDay();
  const diff = start.getDate() - day + (day === 0 ? -6 : 1); // Adjust if the day is Sunday
  start.setDate(diff);
  start.setHours(0, 0, 0, 0);
  return start;
}

function getEndOfWeek(date) {
  const end = getStartOfWeek(date);
  end.setDate(end.getDate() + 6);
  end.setHours(23, 59, 59, 999);
  return end;
}
