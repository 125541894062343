//get current time to utc time
export const getUTCTimeNow = () => {
  var date = new Date();
  var now_utc = Date.UTC(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  );
  return now_utc;
};

/**
 * Returns unix timestamp nearest to the 15 mins.
 *
 * @param {number} time - time in Unix timestamp
 * @returns {number} - unix timestamp
 */
function roundTimeQuarterHour(time) {
  const timeToReturn = new Date(time);

  timeToReturn.setMinutes(Math.round(timeToReturn.getMinutes() / 15) * 15);
  timeToReturn.setSeconds(0);
  timeToReturn.setMilliseconds(0);

  return timeToReturn.getTime();
}

export const getUTCTimeBefore24Hour = () => {
  const now = new Date();
  const twentyFourHoursAgo = new Date(now.getTime() - 24 * 60 * 60 * 1000);

  const now_utc = Date.UTC(
    twentyFourHoursAgo.getUTCFullYear(),
    twentyFourHoursAgo.getUTCMonth(),
    twentyFourHoursAgo.getUTCDate(),
    twentyFourHoursAgo.getUTCHours(),
    twentyFourHoursAgo.getUTCMinutes(),
    twentyFourHoursAgo.getUTCSeconds()
  );
  return now_utc;
};

export const convertSecondsToTime = (seconds) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  const formattedTime = [
    hours.toString().padStart(2, '0'),
    minutes.toString().padStart(2, '0'),
    Math.round(remainingSeconds.toString().padStart(2, '0'))
  ].join(':');

  return formattedTime;
};

export const convertTimestampToDuration = (timestamp) => {
  const msPerSecond = 1000;
  const msPerMinute = 60 * msPerSecond;
  const msPerHour = 60 * msPerMinute;
  const msPerDay = 24 * msPerHour;
  const msPerMonth = 30 * msPerDay;
  const msPerYear = 365 * msPerDay;

  const currentTime = Date.now();
  const timeDifference = currentTime - timestamp;

  const years = Math.floor(timeDifference / msPerYear);
  const months = Math.floor((timeDifference % msPerYear) / msPerMonth);
  const days = Math.floor((timeDifference % msPerMonth) / msPerDay);
  const hours = Math.floor((timeDifference % msPerDay) / msPerHour);
  const minutes = Math.floor((timeDifference % msPerHour) / msPerMinute);
  const seconds = Math.floor((timeDifference % msPerMinute) / msPerSecond);

  if (years) return `${years}y`;
  if (months) return `${months}mo`;
  if (days) return `${days}d`;
  if (hours) return `${hours}h`;
  if (minutes) return `${minutes}m`;
  if (seconds) return `${seconds}s`;
  else return ``;
};

export const getTimestampStartToday = () => {
  const today = new Date();

  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed, so we add 1 and pad with leading zeros
  const day = String(today.getDate()).padStart(2, '0'); // Pad with leading zeros if needed

  const formattedDate = `${year}-${month}-${day}`;

  const startDate = new Date(formattedDate);
  startDate.setHours(0, 0, 0, 0);

  const startTime = startDate.getTime();
  return startTime;
};

export const getWeekOfMonth = (date) => {
  const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  const dayOfWeek = firstDay.getDay(); // 0 (Sunday) to 6 (Saturday)
  const dayOfMonth = date.getDate();

  // Calculate the week of the month
  const week = Math.ceil((dayOfMonth + dayOfWeek) / 7);

  return week;
};

export const formatTime = (seconds) => {
  let hours = Math.floor(seconds / 3600);
  hours = hours === 0 ? '' : `${hours}h`;
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  let formattedTime = `${hours}`;
  if (minutes > 0) {
    formattedTime = formattedTime + ` ${String(minutes).padStart(2, '0')}m`;
  }
  if (remainingSeconds > 0) {
    formattedTime = formattedTime + ` ${String(remainingSeconds).padStart(2, '0')}`;
  }

  return formattedTime;
};

export const secondFormatTime = (seconds) => {
  let hours = Math.floor(seconds / 3600);
  hours = hours === 0 ? '' : `${hours}:`;
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  const formattedTime = `${hours}${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;

  return formattedTime;
};

export function isTimestampInCurrentWeek(timestamp) {
  const now = new Date();
  const currentDay = now.getDay(); // 0 for Sunday, 1 for Monday, ..., 6 for Saturday

  // Calculate the start of the current week (Sunday midnight)
  const startOfWeek = new Date(now);
  startOfWeek.setHours(0, 0, 0, 0);
  startOfWeek.setDate(now.getDate() - currentDay);

  // Calculate the end of the current week (Saturday at 23:59:59.999)
  const endOfWeek = new Date(startOfWeek);
  endOfWeek.setDate(endOfWeek.getDate() + 6);
  endOfWeek.setMilliseconds(999);
  endOfWeek.setSeconds(59);
  endOfWeek.setMinutes(59);
  endOfWeek.setHours(23);

  // Check if the timestamp is within the current week
  return timestamp >= startOfWeek.getTime() && timestamp <= endOfWeek.getTime();
}

export function isTimestampInPreviousWeek(timestamp) {
  const now = new Date();
  const currentDay = now.getDay(); // 0 for Sunday, 1 for Monday, ..., 6 for Saturday

  // Calculate the start of the current week (Sunday midnight)
  const startOfWeek = new Date(now);
  startOfWeek.setHours(0, 0, 0, 0);
  startOfWeek.setDate(now.getDate() - currentDay);

  // Calculate the end of the current week (Saturday at 23:59:59.999)
  const endOfWeek = new Date(startOfWeek);
  endOfWeek.setDate(endOfWeek.getDate() + 6);
  endOfWeek.setMilliseconds(999);
  endOfWeek.setSeconds(59);
  endOfWeek.setMinutes(59);
  endOfWeek.setHours(23);

  // Calculate the start of the previous week
  const startOfPreviousWeek = new Date(startOfWeek);
  startOfPreviousWeek.setDate(startOfPreviousWeek.getDate() - 7);

  // Check if the timestamp is within the previous week
  return timestamp >= startOfPreviousWeek.getTime() && timestamp < startOfWeek.getTime();
}

function getUserTimeZone() {
  const dateTimeFormat = new Intl.DateTimeFormat();
  const timeZone = dateTimeFormat.resolvedOptions().timeZone;
  return timeZone;
}

export { roundTimeQuarterHour, getUserTimeZone };

export const daysOfWeek = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

export function formatSecondsToHoursMinutes(seconds) {
  if (!isFinite(seconds)) {
    return `0h`;
  }
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);

  return `${String(hours)}h ${minutes ? `${String(minutes)}m` : ''}`;
}

export function convertTimestampToIsoString(timestamp) {
  const date = new Date(timestamp);
  const isoString = date.toISOString();

  return isoString;
}

export const timeAmFromTimestamp = (timestamp) => {
  if (!timestamp) return '';
  // Create a Date object from the timestamp
  const date = new Date(timestamp);
  // Extract the time components
  const hours = date.getHours();

  // Format the time as 8 AM
  const ampm = hours < 12 ? 'a' : 'p';
  const displayHour = hours % 12 === 0 ? 12 : hours % 12;

  const formattedTime = `${displayHour}${ampm}`;
  return formattedTime;
};

export const listArrayWorkTimes = () => {
  const now = new Date();
  const year = now.getFullYear();
  const month = now.getMonth(); // months are 0-based in JavaScript
  const day = now.getDate();

  const startHour = 8;
  const endHour = 17;

  const timestamps = [];

  // Generate timestamps from 8 AM to 5 PM
  for (let hour = startHour; hour <= endHour; hour++) {
    const date = new Date(year, month, day, hour, 0, 0, 0);
    timestamps.push(date.getTime());
  }
  return timestamps;
};

export const isTaskToday = (timestamp) => {
  const taskDate = new Date(timestamp);
  const now = new Date();

  return (
    taskDate.getFullYear() === now.getFullYear() &&
    taskDate.getMonth() === now.getMonth() &&
    taskDate.getDate() === now.getDate()
  );
};

export function getFormattedTodayDate(hour) {
  const date = new Date();
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(hour).padStart(2, '0');
  const minutes = '00';
  return `${year}-${month}-${day}T${hours}:${minutes}`;
}

export function formatMuiDateToISOString(date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  return `${year}-${month}-${day}T${hours}:${minutes}`;
}

export const convertAmPmToTimestamp = (time) => {
  const isPM = time.includes('p');
  let hours = parseInt(time);

  if (isPM && hours !== 12) {
    hours += 12;
  } else if (!isPM && hours === 12) {
    hours = 0;
  }

  const date = new Date();
  date.setHours(hours, 0, 0, 0); // set hours, minutes, seconds, and milliseconds

  return date.getTime();
};

export const convertTo24Hour = (hourString) => {
  const hour = parseInt(hourString.slice(0, -1));
  const isPM = hourString.slice(-1) === 'p';

  if (isPM) {
    return hour === 12 ? 12 : hour + 12;
  } else {
    return hour === 12 ? 0 : hour;
  }
};

export function generateHours(startTime, endTime) {
  console.log(startTime, endTime);
  let start = startTime ? startTime.split('T')[1] : '07:00';
  let end = endTime ? endTime.split('T')[1] : '18:00';
  start = new Date(`1970-01-01T${start}:00Z`);
  end = new Date(`1970-01-01T${end}:00Z`);

  // If the end time is earlier in the day than the start time, adjust the end time to be on the next day
  if (end <= start) {
    end.setUTCDate(end.getUTCDate() + 1);
  }

  const hours = [];

  // Generate the list of hours in AM/PM format
  let current = new Date(start);
  while (current <= end) {
    const hours12 = current.getUTCHours() % 12 || 12; // convert 24h to 12h format
    const ampm = current.getUTCHours() < 12 ? 'a' : 'p';
    hours.push(`${hours12}${ampm}`);
    current.setUTCHours(current.getUTCHours() + 1);
  }

  return hours;
}

export const convertHourToAP = (hour) => {
  let convertedHour = hour.getHours();

  const ampm = convertedHour >= 12 ? 'p' : 'a';

  convertedHour = convertedHour % 12;
  convertedHour = convertedHour ? convertedHour : 12;
  return `${convertedHour}${ampm}`;
};

//time format  : "09:00"
export function getDateFrom24Format(time) {
  const currentDate = new Date();
  return new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate(),
    time?.split(':')?.[0],
    0,
    0,
    0
  );
}

export const isIsoString = (dateStr) => {
  const isoRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/;
  console.log("isoRegex.test(dateStr)==", isoRegex.test(dateStr))
  return isoRegex.test(dateStr);
};

export const isTimeString = (timeStr) => {
  const timeRegex = /^\d{2}:\d{2}$/;
  return timeRegex.test(timeStr);
};

export const isoStringToTimestamp = (isoString) => {
  const date = new Date(isoString);
  return date.getTime();
};