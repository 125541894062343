import styled from '@emotion/styled';

export const ErrorBoundaryContainer = styled.div`
  background: transparent;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;

  .error-content {
    p {
      color: #f5f5f5;
      text-align: center;
      font-family: wotfardregular;
      font-size: 1rem;
      font-style: normal;
      font-weight: 600;
      line-height: 145%;
    }
    .or-text {
      margin: 10px 0;
    }
    .refresh-button {
      --background-hover: transparent;
      --background-activated: transparent;
      --background-focused: transparent;
      --background: #fff;
      --border-radius: 5px;
      width: 100%;
      --padding: 12px;
      height: 45px;
      box-sizing: border-box;
      color: #0c0c0c;
      .button-content {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 15.75px;
        span {
          text-transform: capitalize;
        }
      }
    }
  }
`;
