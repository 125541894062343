export const playerActionTypes = {
  PLAY_MUSIC_TRACK: 'PLAY_MUSIC_TRACK',
  PAUSE_MUSIC_TRACK: 'PAUSE_MUSIC_TRACK',
  RESUME_MUSIC_TRACK: 'RESUME_MUSIC_TRACK',
  STOP_MUSIC_TRACK: 'STOP_MUSIC_TRACK',

  UPDATE_MUSIC_DURATION: 'UPDATE_MUSIC_DURATION',
  UPDATE_PLAYER_PROGRESS: 'UPDATE_PLAYER_PROGRESS',

  SET_USER_PROFILE_DATA: 'SET_USER_PROFILE_DATA',
  SET_NOTIFICATION_DATA: 'SET_NOTIFICATION_DATA',
  SET_EFFECT_SWIPER: 'SET_EFFECT_SWIPER'
};
