import exerciseImg from '../assets/icons/mixl/exercise.svg';
import goalIconSelected from '../assets/icons/mixl/goal-selected.svg';
import goalIconUnSelected from '../assets/icons/mixl/goal-unselected.svg';
import meditatioImg from '../assets/icons/mixl/meditation.svg';
import musicIconSelected from '../assets/icons/mixl/music-selected.svg';
import musicIconUnselected from '../assets/icons/mixl/music-unselected.svg';
import warmupImg from '../assets/icons/mixl/warmup-img.svg';
import { IMixlSubscription } from '../Types/mixlTypes';
import { convertTo24Hour, generateHours } from './dateUtils';

export function getMixlTooltipID(isPremiumUser: boolean): string {
  if (!isPremiumUser) {
    return '';
  }
  return 'mixl-tooltip-lock';
}

export const flowStates = {
  calm: {
    phase: 'calm',
    title: 'Calm Phase',
    wave_name: 'delta',
    frequency_min: '1',
    frequency_max: '3',
    description:
      'is where it all begins for relaxation, helping you focus better by creating a peaceful atmosphere with soothing sounds tailored for deep concentration',
    short: 'Delta waves for deep relaxation'
  },
  //todo: please remove focus phase. it's causing lot's of confusion
  focus: {
    phase: 'flow',
    title: 'Flow Phase',
    wave_name: 'theta',
    frequency_min: '3.5',
    frequency_max: '7.5',
    description:
      'crafted to help you concentrate and think clearly, these tunes create a focused atmosphere, boosting your attention and clarity for optimal productivity',
    short: 'Theta waves for attention and clarity'
  },
  flow: {
    phase: 'flow',
    title: 'Flow Phase',
    wave_name: 'theta',
    frequency_min: '3.5',
    frequency_max: '7.5',
    description:
      'crafted to help you concentrate and think clearly, these tunes create a focused atmosphere, boosting your attention and clarity for optimal productivity',
    short: 'Theta waves for attention and clarity'
  }
};

export const mixlTabs = {
  initial: 'initial',
  endSession: 'end',
  summary: 'summary',
  timer: 'timer',
  task: 'task',
  music: 'music',
  analytics: 'analytics',
  settings: 'settings',
  profile: 'profile',
  break: 'break',
  team: 'team',
  warmup: 'warmup',
  aicard: 'aicard',
  timeguard: 'timeguard',
  blockedsite: 'blockedsite',
  badges: 'badges',
  todo: 'todo'
  // profile: 'profile',
  // generation: 'generation'
};

export const MUSIC_STATIONS = [
  { name: 'house', abrev: 'house', isPremium: false },
  { name: 'ambient', abrev: 'ambient', isPremium: false },
  { name: 'afrobeats', abrev: 'afrobeats', isPremium: true },
  { name: 'hip hop', abrev: 'hip_hop', isPremium: true },
  { name: 'lofi', abrev: 'lofi', isPremium: true },
  { name: 'reggae', abrev: 'reggae', isPremium: true },
  { name: 'funk', abrev: 'funk', isPremium: true },
  { name: 'trap', abrev: 'trap', isPremium: true },
  { name: 'disco', abrev: 'disco', isPremium: true }
];

export const getBackgroundGoal = (index) => {
  switch (index) {
    case 0:
      return 'transparent';
    case 1:
      return '#FFFFFF26';
    case 2:
      return '#FFFFFF0D';
  }
};

export const getBottomGoal = (index) => {
  switch (index) {
    case 0:
      return '#fe4d8a';
    case 1:
      return '#ffffff';
    case 2:
      return '#313131';
  }
};

export const breaks = {
  exercise: {
    name: 'Mind Body Break',
    description: 'time for light exercise',
    img: exerciseImg,
    title: 'Seated Shoulder Roll',
    tasks: [
      {
        time: '30s',
        description: 'Roll shoulders forward, then backward.'
      },
      {
        time: '30s',
        description: 'Bend neck side to side. Repeat.'
      }
    ]
  },
  meditation: {
    name: 'Mind Body Break',
    description: 'time for focus meditation',
    img: meditatioImg,
    title: 'Breathwork',
    tasks: [
      {
        time: '4s',
        description: 'Inhale through nose.'
      },
      {
        time: '4s',
        description: 'Hold breath'
      },
      {
        time: '4s',
        description: 'Exhale through mouth. Repeat 4 times.'
      }
    ]
  }
};

export const warmup = {
  name: 'Creative Warmup',
  description: 'time to unlock focus',
  img: warmupImg,
  title: 'Get Yourself Going',
  tasks: [
    {
      time: '1m',
      description: 'Dream big, imagine anything is possible.'
    },
    {
      time: '1m',
      description: 'Zoom in. Visualize the details of success.'
    }
  ]
};

export const subscriptionsTypes = {
  team: 'team_invite'
  //please add here the rest of types
};

export const siteBlockingTypes = {
  warn: 'warn',
  block: 'block'
};

export const TASK_TABS = [
  {
    name: 'first',
    value: 'Do First'
  },
  {
    name: 'next',
    value: 'Do Next'
  },
  {
    name: 'done',
    value: 'done'
  }
];

export const TASK_DIFFICULTY = [
  {
    name: 'hard',
    value: 'Hard Tasks',
    color: '#FE4D8A'
  },
  {
    name: 'easy',
    value: 'Easy Tasks',
    color: '#42ACDA'
  },
  {
    name: 'idea',
    value: 'Ideas',
    color: '#6042DA'
  }
];

export const MIND_AI_TASK_TABS = [
  {
    name: 'first',
    value: 'Important'
  },
  {
    name: 'next',
    value: 'General'
  }
];

export const getTaskPriorityTab = (priority) => {
  switch (priority) {
    case 'urgent':
      return TASK_TABS[0]?.name;
    case 'high':
      return TASK_TABS[0]?.name;
    case 'normal':
      return TASK_TABS[1]?.name;
    case 'low':
      return TASK_TABS[1]?.name;
  }
};

export const toolbarButtons = [
  {
    name: mixlTabs.summary,
    selectedIcon: goalIconSelected,
    unselectedIcon: goalIconUnSelected,
    index: 0
  },
  {
    name: mixlTabs.music,
    selectedIcon: musicIconSelected,
    unselectedIcon: musicIconUnselected,
    index: 1
  }
];

export const timerOptions = [
  {
    id: 'pomodoro',
    name: 'Pomodoro',
    workTime: 1500, //seconds
    breakTime: 300
  },
  {
    id: 'long-pomodoro',
    name: 'Long Pomodoro',
    workTime: 3000,
    breakTime: 600
  },
  {
    id: 'xl-pomodoro',
    name: 'XL Pomodoro',
    workTime: 5400,
    breakTime: 1800
  }
];

export const checkPremiumSubscription = (payload?: IMixlSubscription) => {
  if (!payload) {
    return false;
  }
  if (Date.now() > payload?.expireDate) {
    return false;
  }

  if (payload.userType !== 'premium') {
    return false;
  }

  return true;
};

export const getNextTaskTime = (tasks, startTime, endTime) => {
  const findNextHour = (currentHour) => {
    for (const hour of hoursList) {
      if (hour > currentHour) return hour;
      else if (hour === currentHour) return hour + 1;
    }
    return null;
  };

  const now = new Date();
  console.log(startTime, endTime);
  const hoursList = generateHours(startTime, endTime).map(convertTo24Hour);
  console.log('hoursList', hoursList);
  if (hoursList.length === 0) return '';
  const currentHour = now.getHours();

  // If tasks array is empty or no valid timestampStart
  if (!tasks || tasks?.length === 0) {
    const currentHour = now.getHours();
    let nextHour = findNextHour(currentHour);

    if (nextHour === null) {
      // Return tomorrow's first hour if current hour is more than hoursList
      nextHour = hoursList[0];
      const nextHourDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1, nextHour, 0, 0, 0);
      return nextHourDate.getTime();
    } else if (currentHour < hoursList[0]) {
      // Use the first hour in the hoursList if the current hour is less
      nextHour = hoursList[0];
    }

    const nextHourDate = new Date(now.getFullYear(), now.getMonth(), now.getDate(), nextHour, 0, 0, 0);
    return nextHourDate.getTime();
  }

  // Create a set of hours that are already taken by tasks
  const takenHours = new Set(tasks.map((task) => new Date(task.startTimestamp).getHours()));
  console.log('takenHours', takenHours, takenHours.size);
  // Find the first available hour in the hoursList
  if (takenHours.size > 0) {
    const nextCurrentHour = findNextHour(currentHour);
    console.log('nextHour', nextCurrentHour, 'hoursList', hoursList);
    let nextHour = hoursList.find((hour) => !takenHours.has(hour));
    console.log('nextHour', nextHour);
    // If no available hour is found for today
    if (nextHour === undefined) {
      return '';
    } else {
      if (nextCurrentHour >= nextHour) {
        if (!takenHours.has(nextCurrentHour)) {
          nextHour = nextCurrentHour;
          console.log('nextHour', nextHour);
        } else {
          nextHour = hoursList.find((hour) => !takenHours.has(hour) && hour >= nextCurrentHour);
          console.log('nextHour', nextHour);
          if (!nextHour) return '';
        }
      }
      // Create a new Date object for the next available hour
      const nextTaskDate = new Date(now.getFullYear(), now.getMonth(), now.getDate(), nextHour, 0, 0, 0);

      // Get the timestamp for the next available time
      const nextTaskTimestamp = nextTaskDate.getTime();
      return nextTaskTimestamp;
    }
  } else {
    let nextHour;
    nextHour = findNextHour(currentHour);
    if (nextHour === null) {
      // Return tomorrow's first hour if the next hour is more than hoursList
      nextHour = hoursList[0];
      const nextHourDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1, nextHour, 0, 0, 0);
      const nextTaskTimestamp = nextHourDate.getTime();
      return nextTaskTimestamp;
    }
    // Create a new Date object for the next available hour
    const nextTaskDate = new Date(now.getFullYear(), now.getMonth(), now.getDate(), nextHour, 0, 0, 0);

    // Get the timestamp for the next available time
    const nextTaskTimestamp = nextTaskDate.getTime();
    return nextTaskTimestamp;
  }
};



export const staticBlocks = ['break', 'meeting', 'exercise', 'learning'];
