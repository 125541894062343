export type IQuote = {
  text: string;
  author: string;
};
export const getQuoteWarmup: IQuote = () => {
  const defaultWarmupQuotes: IQuote[] = [
    {
      text: 'Happiness comes from your own actions',
      author: 'Dalai Lama'
    },
    {
      text: 'The biggest risk is not taking any risk.',
      author: 'Mark Zuckerberg'
    },
    {
      text: 'What you do today can improve all your tomorrows.',
      author: 'Ralph Marston'
    },
    {
      text: 'Just do it.',
      author: 'Nike'
    },
    {
      text: 'Always deliver more than expected.',
      author: 'Larry Page'
    },
    {
      text: 'Chase the vision.',
      author: 'Tony Hsieh'
    },
    {
      text: 'Focus on being productive instead of busy.',
      author: 'Tim Ferriss'
    },
    {
      text: 'Productivity is never an accident',
      author: 'Paul J. Meyer'
    },
    {
      text: 'Identify the essential. Eliminate the rest.',
      author: 'Leo Babauta'
    },
    {
      text: 'Luck is not chance, it’s toil.',
      author: 'Emily Dickinson'
    }
  ];
  const randomIndex = Math.floor(Math.random() * defaultWarmupQuotes.length);
  return defaultWarmupQuotes[randomIndex];
};

export const getQuoteBreak: IQuote = () => {
  const defaultBreakQuotes: IQuote[] = [
    {
      text: 'Refresh and renew yourself, your body, your mind.',
      author: 'Ralph Marston'
    },
    {
      text: 'When the mind relaxes, inspiration often follows.',
      author: 'Phil Jackson'
    },
    {
      text: 'Some of your best ideas come when you take a break.',
      author: 'Gautam Singhania'
    },
    {
      text: 'The time to relax is when you don’t have time for it.',
      author: 'Sydney J. Harris'
    },
    {
      text: 'Sometimes the most urgent thing you can do is a break.',
      author: 'Ashleigh Brilliant'
    },
    {
      text: 'Taking breaks improves your creativity and productivity.',
      author: 'Tom Rath'
    },
    {
      text: "The more relaxed you can get, the better you'll do.",
      author: 'Isaac Mizrahi'
    },
    {
      text: 'Relax, refresh and refocus.',
      author: 'Lailah Gifty Akita'
    },
    {
      text: 'For fast-acting relief, try slowing down',
      author: 'Lily Tomlin'
    },
    {
      text: 'Relax. Enjoy the moment. Be yourself',
      author: 'Gene Coates'
    }
  ];
  const randomIndex = Math.floor(Math.random() * defaultBreakQuotes.length);
  return defaultBreakQuotes[randomIndex];
};
