import breakIcon from '../assets/icons/mixl/break-today-icon.svg';
import exerciseIcon from '../assets/icons/mixl/exercise-today-icon.svg';
import blackFlagImg from '../assets/icons/mixl/flags/black.svg';
import highFlagImg from '../assets/icons/mixl/flags/high.svg';
import lowFlagImg from '../assets/icons/mixl/flags/low.svg';
import normalFlagImg from '../assets/icons/mixl/flags/normal.svg';
import urgentFlagImg from '../assets/icons/mixl/flags/urgent.svg';
import learningIcon from '../assets/icons/mixl/learning-today-icon.svg';
import meetingIcon from '../assets/icons/mixl/meeting-today-icon.svg';
import doneProgressIcon from '../assets/icons/mixl/progress/done.svg';
import openProgressIcon from '../assets/icons/mixl/progress/open.svg';
import runningProgressIcon from '../assets/icons/mixl/progress/running.svg';
import { getUserToken } from '../components/firebase/firebase';
import { TaskDayTypes, TaskTagTypes } from '../redux/mixl/types';
import { IMixlTask } from '../utils/Types';
import Request from './Request';

const { PostSecuredData, GetSecuredData } = Request();

export const fetchClickupTasks = async () => {
  const token = await getUserToken();
  const { data } = await GetSecuredData('mixer/get_clickup_tasks', `${token}`);
  if (!data && !data?.length > 0) {
    return [];
  }
  const tasks: IMixlTask[] = await Promise.all(
    data.map((item) => {
      const date_created = parseInt(item?.date_created ?? Date.now());
      const task: IMixlTask = {
        details: item?.description ?? '',
        status: item?.status ?? { status: 'open' },
        name: item?.name ?? '',
        isTodayTask: true,
        type: 'clickup',
        tagName: '',
        priority: item?.priority ?? {
          color: '#6fddff',
          id: '3',
          orderindex: '3',
          priority: 'normal'
        },
        createdAt: date_created,
        id: item?.id ?? '',
        startTimestamp: date_created
      };

      return task;
    })
  );
  return tasks;
};
export const taskTagOptionList = Object.keys(TaskTagTypes).map((e) => {
  const value = TaskTagTypes[e];
  return { value, className: value };
});

export const taskDayOptionList = Object.keys(TaskDayTypes).map((e) => {
  const value = TaskDayTypes[e];
  return { value, className: value };
});

export const getTaskDateText = (isToday: boolean) => {
  if (isToday) {
    return TaskDayTypes.today;
  }
  return TaskDayTypes.later;
};

export const progressOptionList = [
  { value: 'open', icon: openProgressIcon },
  { value: 'in progress', icon: runningProgressIcon },
  { value: 'done', icon: doneProgressIcon }
];

export const getTaskProgressIcon = (status: string) => {
  switch (status) {
    case 'in progress':
      return runningProgressIcon;
    case 'done':
      return doneProgressIcon;
    case 'open':
    default:
      return openProgressIcon;
  }
};

export const onClickupAuth = async () => {
  try {
    const token = await getUserToken();
    const { url } = await PostSecuredData(
      'authapi/createAuthLink',
      {
        auth: 'clickup',
        baseUrl: window.location.hostname
      },
      token
    );
    return url;
  } catch (error) {
    console.log('error', error);
    throw new Error('Issue with Login');
  }
};

export const getImgPriority = (priority: string) => {
  // console.log('priority===', priority);
  switch (priority) {
    case 'low':
      return lowFlagImg;
    case 'normal':
      return normalFlagImg;
    case 'high':
      return highFlagImg;
    case 'urgent':
      return urgentFlagImg;
    default:
      return blackFlagImg;
  }
};

export const getImgEvent = (type: string) => {
  // console.log('priority===', priority);
  switch (type) {
    case 'exercise':
      return exerciseIcon;
    case 'learning':
      return learningIcon;
    case 'break':
      return breakIcon;
    case 'meeting':
      return meetingIcon;
    default:
      return breakIcon;
  }
};
