import { drawers } from './type';

const appDrawerState = {
  outputDevice: null,
  showMobilePromo: false,
  upcomingEvent: null,
  platformError: '',
  showLoadingChat: false,
  showMixlPlusModal: false,
  showResumeSessionModal: false
};

export const drawerReducer = (state = appDrawerState, action) => {
  switch (action.type) {
    case drawers.SHOW_MIXL_PLUS_MODAL: {
      return { ...state, showMixlPlusModal: action.isOpen };
    }
    case drawers.UPDATE_LOADING_CHAT: {
      return { ...state, showLoadingChat: action.isLoading };
    }
    case drawers.SHOW_UPCOMING_EVENT_MODAL: {
      return { ...state, upcomingEvent: action.payload };
    }
    case drawers.SET_PLATFORM_ERROR: {
      return { ...state, platformError: action.payload };
    }
    case drawers.SHOW_MOBILE_PROMO: {
      return { ...state, showMobilePromo: action.isOpen };
    }
    case drawers.OUTPUT_DEVICE_CHANGED: {
      return { ...state, outputDevice: action.outputDevice };
    }
    case drawers.SHOW_RESUME_SESSION_MODAL: {
      return { ...state, showResumeSessionModal: action.isOpen };
    }
    default:
      return state;
  }
};
