
export const IMixlOffer = {
  slack: 'slack',
  referral: 'referral'
}

export const web3EventTypes = {
  UPDATE_PREMIUM_OFFER: 'UPDATE_PREMIUM_OFFER',
  DISABLE_EXPERIMENTAL_FEATURE: 'DISABLE_EXPERIMENTAL_FEATURE',
};
