import { Share } from '@capacitor/share';
import { toast } from 'react-toastify';

import Bronze from '../assets/icons/mixl/badges/bronze.svg';
import FlowIcon from '../assets/icons/mixl/badges/flow.svg';
import Gold from '../assets/icons/mixl/badges/gold.svg';
import GuruIcon from '../assets/icons/mixl/badges/guru.svg';
import Silver from '../assets/icons/mixl/badges/silver.svg';
import StarterIcon from '../assets/icons/mixl/badges/starter.svg';
import SteadyIcon from '../assets/icons/mixl/badges/steady.svg';
import TeamIcon from '../assets/icons/mixl/badges/team.svg';
import { getUserToken, recordAnalytics } from '../components/firebase/firebase';
import Request from '../Services/Request';
import { isCapacitor } from './platformUtils';

const { GetSecuredData } = Request();

export function getUserName(name) {
  if (!name) {
    return '';
  }
  if (name.length > 18) {
    return `${name.substring(0, 18)}...`;
  }

  return name;
}

export async function addUser(user_details, presentAlert) {
  recordAnalytics('userShareInviteStarted');
  const { hostname } = window.location;
  const payload = await getPayload(user_details);
  if (isCapacitor) {
    console.log('check navigator ', navigator?.share);
    await Share.share(payload)
      .then((result) => {
        console.log('share result ', result);
        if (!result.activityType) {
          throw new Error('no activity found');
        }
        recordAnalytics('userShareInviteSuccess');
        postShareActivity(user_details).catch((errBackend) => {
          console.log('something went wrong sending data backend ', errBackend);
        });
      })
      .catch((errShare) => {
        recordAnalytics('userShareInviteFailed');
        console.log('something went wrong sharing ', errShare);
      });
    return;
  }
  if (!window.navigator?.share) {
    navigator.clipboard.writeText(`https://${hostname}/connect?refer_code=${user_details?.referralId}`);
    toast.success('Invite Link Copied', {
      className: 'slow-toast'
    });

    setTimeout(() => {
      toast.dismiss(); // Manually dismiss the toast after desired duration
    }, 1000);
    return;
  }

  if (navigator.share) {
    window.navigator
      .share(payload)
      .then(async function () {
        console.log('Successful share');
        try {
          await postShareActivity(user_details);
        } catch (e) {
          console.log('Could not get user', e);
          return;
        }
      })
      .catch(function (error) {
        console.log('Error sharing:', error);
      });
  } else {
    presentAlert({
      header: "Please enable 'web-share' in your browser settings!",
      buttons: [
        {
          text: 'OK',
          role: 'confirm',
          handler: () => {}
        }
      ]
    });
  }
}

export async function getPayload(user_details) {
  const { hostname } = window.location;
  const text = `Hi 👋🏾 Here's your Mixl private invite! \n
  Mixl helps you maximize time and do more in life. \n
  on iOS: https://apps.apple.com/us/app/mixl-ai/id6446263401 \n
  on web: https://${hostname} \n
  invite code: ${user_details?.referralId}`;

  const payload = {
    title: `MIXL`,
    text,
    url: `https://apps.apple.com/us/app/lets-glo/id6446263401`
  };

  return payload;
}

export async function postShareActivity(user_details) {
  const token = await getUserToken();
  const { data } = await GetSecuredData(`moderator?email=${user_details?.email}`, token);
  const maxInviteShareLimit = data?.maxInviteShareLimit;
  const inviteShareCount = parseInt(data?.inviteShareCount || 0);
  if (inviteShareCount < maxInviteShareLimit) {
    const token = await getUserToken();
    await PutData(
      `moderator`,
      {
        inviteShareCount: inviteShareCount + 1
      },
      token
    );
  }
}

export const TEAM_BADGES = {
  starter: {
    icon: StarterIcon,
    name: 'starter'
  },
  steady: {
    icon: SteadyIcon,
    name: 'steady'
  },
  team: {
    icon: TeamIcon,
    name: 'team'
  },
  flow: {
    icon: FlowIcon,
    name: 'flow'
  },
  guru: {
    icon: GuruIcon,
    name: 'guru'
  }
};
export const TEAM_MEDALS = [Gold, Silver, Bronze];
