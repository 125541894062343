import { getPlatforms, isPlatform } from '@ionic/react';
import { browserVersion, isChrome, isEdge, isMobile, isSafari } from 'react-device-detect';

console.log('browserVersion ', browserVersion);

const safariVersion = 12;
const chromeVersion = 120;
const supportedChrome = isChrome && parseInt(browserVersion) >= chromeVersion;
const supportedSafari = isSafari && parseInt(browserVersion) >= safariVersion;
const isEdgeBrowser = isEdge && parseInt(browserVersion) >= chromeVersion;
const isBraveBrowser = Boolean(navigator?.brave);
const isCapacitor = isPlatform('capacitor');
const platforInfo = getPlatforms();

const inIframe = () => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

const isExtenstionSidebar = () => {
  return inIframe() && window.name === 'sidebar';
};
const isOffscreenIframe = () => {
  return inIframe() && window.name === 'offscreen';
};

// todo: make this secure
const postIframeMessage = (data) => {
  window.top?.postMessage(data, '*');
};

const inAppBrowser = navigator.userAgent.includes('Instagram');
window.platforInfo = platforInfo;

const isSupportedBrowser =
  supportedChrome || supportedSafari || isCapacitor || inAppBrowser || isBraveBrowser || isEdgeBrowser;

const isSupportedDevice = isMobile || isCapacitor;

const isIPad = () => {
  const ua = navigator.userAgent;
  return /iPad|Macintosh/.test(ua) && 'ontouchend' in document;
};

export {
  isOffscreenIframe,
  isSupportedDevice,
  isSupportedBrowser,
  safariVersion,
  chromeVersion,
  isCapacitor,
  platforInfo,
  inAppBrowser,
  isIPad,
  inIframe,
  isExtenstionSidebar,
  postIframeMessage
};
